import { create } from "zustand";
import { MOBILE_CUTOFF } from "./constants";
import { UseDevice } from "./types";

export const useDeviceStore = create<{
	device: "mobile" | "desktop";
}>(() =>
	typeof window === "undefined"
		? {
				device: "mobile",
			}
		: {
				device: window.innerWidth < MOBILE_CUTOFF ? "mobile" : "desktop",
			},
);
if (typeof window !== "undefined") {
	window.addEventListener("resize", () => {
		const existingDevice = useDeviceStore.getState().device;
		const newDevice = window.innerWidth < MOBILE_CUTOFF ? "mobile" : "desktop";
		if (existingDevice !== newDevice) {
			useDeviceStore.setState({
				device: newDevice,
			});
		}
	});
}

const useDevice: UseDevice = () => {
	const device = useDeviceStore((state) => state.device);

	return {
		device,
		native: false,
	};
};
export default useDevice;
